.inputs-group-courier {
	display: flex;

	&.column {
		flex-direction: column;
	}
	&.row {
		flex-direction: row;
	}
	&.row-4 {
		justify-content: space-between;

		.input-row {
			width: 22%;
		}
		&.nofloor {
			margin-right: 26%;

			.input-row {
				width: 29.727%
			}
		}
	}
	&.row-2 {
		justify-content: space-between;
		margin-right: 52%;

		.input-row {
			width: 45.83%;
		}
	}
}
