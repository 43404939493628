// common colors
$main: #00b2f2;
$newMain: #005FC6;
$mainHover: #005FC6;
$secondary: #ffbf26;
$secondaryHover: #f6b61e;
$third: #00ac83;
$thirdHover: #089976;
$success: #00ac45;
$ultraSuccess: #65B32E;
$error: #fd3321;
$disable: #d3d3d4;
$base: #f7f8f9;
$light: #e4f2f8;
$light2: #FDFBEB;
$light3: #E8F6F4;
$light4: #E3E8EB;
$light5: #F5F5F5;
$light6: #E6E6E6;
$purple: #494bc1;
$purpleHover: #3234a5;
$violet: #8e09aa;
$gray: #d4d4d4;
$vbColor: #7360F2;
$npLogoColor: #ED1C24;
$teal: #CDE5EE;
$secondaryDetailsDark: #666666;
$standartBlue: #00B6ED;
$darkGreen: #008A35;
$white: #fff;
$lightGrey: #E4E4E4;
$appleGreen: #83B81A;
$grayBlue: #4A5D90;
$lightBlue: #ECF4F8;
$blue: #4aa8f8;
$mediumYellow: #FFD677;
$whiteSmoke: #efefef;
$azureishWhite: #D2E5F1;
$mainlyBlue: #4D7D9A;
$paleYellow: #FFF8BE;

// text colors
$dark: #222d4a;
$secondaryText: #777e90;
$darkSecondary: #292b31;
$linkText: #019FFF;
$textColorLight: #e5e5e5;
$trueDark: #000;
$trueWhite: #fff;
$devilGray: #666;
$dangerText: #f00000;
$safetyText: $success;
$darkPurple: #6266D9;
$darkTurquoise: #00CFE4;
$darkGray: #50535A;
$blueDark: #005FC6;
$secondaryGray: #6A6B81;
$red: #E81602;
$green: #068838;
$green2: #00A046;

// border colors
$border: #d5d5d5;
$borderSecondary: #d4d4d4;
$borderLight: #dde0ea;
$borderLight2: #D2D5D9;
$borderXanthousYellow: #ebb12b;
$borderDarkGray: #bbbec7;
$borderSold: #BBBEC7;
$borderGray: #E1E1E1;
$btnBorder: #C2C7D2;
$tableActiveBorder: #9DE0F8;
$tableActive: #ebfaff;
$tableHover: #c7c7c7;

// label colors
$new: #00ac83;
$action: $error;
$best: #0097cd;
$hit: #ff7816;
$sold: $error;
$exclusive: #494bc1;
$advice: #F34C5C;
$reviews: #494bc1;
$present: #A643D5;
$cashback: #FFA800;
$discount: $error;
$drawing: $third;
$installment: #375BE9;
$filterClearBlue: #40B3E0;
$notifications: rgba(74, 93, 144, 0.94);

// fonts
$baseFont: 'Wix Madefor Display', sans-serif;
$secondFont: 'Helvetica', sans-serif;
$thirdFont: 'Roboto', sans-serif;
$textBaseSize: 14px;

$textSmallSize: 12px;
$textMediumSize: 16px;
$textLargeSize: 18px;
$titleBaseSize: 24px;
$titleMediumSize: 32px;
$titleLargeSize: 48px;
$textUltraSmallSize: 10px;
$textMediumSize: 16px;
$titleLargeSize: 48px;
$titleSmallSize: 20px;


// common vars
$baseBorderRadius: 4px;
$commonBorderRadius: 100px;
$largeBorderRadius: 16px;
$borderRadiusXS: $baseBorderRadius; // Extra Small
$borderRadiusSM: $baseBorderRadius * 2; // Small
$commonBoxShadow: 0 4px 4px rgba(34, 45, 74, 0.1);
$popUpBoxShadow: 0 3px 4px #d4d4d4;
$modalBackdropZindex: 2147483000;
$blockHeight: 48px;
$productItemBgColor: #fff;