body {
  background-color: $base;
  font-weight: 400;
  color: $dark;
  font-size: $textBaseSize;
  font-family: $baseFont;
  overflow-x: hidden;

  &.body--modal-open,
  &.no-scroll {
    overflow-y: hidden;
  }
}