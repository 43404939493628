@import "../../../../../new-moyo/src/styles/main/vars";

.order-status {
  &_title {
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 32px;
    color: $dark;
  }

  &_check {
    &_info {
      margin-top: 16px;
      background-color: $light;
      padding: 23px 18px 23px 50px;
      position: relative;
      border-radius: 4px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 18px;
        width: 21px;
        height: 21px;
        background: url('../../../../../new-moyo/src/img/icon-info.svg') no-repeat center;
        background-size: contain;
      }

      &_text {
        font-size: 14px;
        color: #222d4a;
        line-height: 1.15;
        font-family: $baseFont;
        display: block;
      }
    }
  }

  &_main {
    display: grid;
    grid-template-columns: minmax(400px, 500px) minmax(600px, 1015px);
    grid-gap: 4px;
  }

  &_info {
    width: 100%;
    border-radius: $baseBorderRadius;
    background-color: #fff;
    padding: 20px 24px;
    margin-top: 4px;

    &:first-child {
      margin-top: 0;
    }

    &_title {
      font-size: 24px;
      color: $dark;
      font-weight: 700;

      &.closable {
        position: relative;
        cursor: pointer;
        user-select: none;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 12px;
          height: 8px;
          background: url('../../../../../new-moyo/src/img/icon-arrow.svg') no-repeat center;
          background-size: contain;
        }
      }

      &.is-open {
        &::after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &_details {
      margin-top: 20px;

      &_item {
        display: flex;
        align-items: flex-start;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &_key {
          width: 50%;
          color: $secondaryText;
        }

        &_value {
          width: 50%;
          color: $darkSecondary;
          text-align: right;

          .status {
            padding: 4px 8px 3px;
            border-radius: 2px;
            text-transform: uppercase;
            text-align: center;
            font-size: 10px;
            font-weight: 700;
            color: #fff;
            background-color: #d3d3d4;

            &--prepare {
              background-color: #ff7816;
            }

            &--in-progress {
              background-color: #0097cd;
            }

            &--ok {
              background-color: #00ac83;
            }

            &--canceled {
              background-color: #fd3321;
            }
          }
        }
      }
    }

    &_track-btn {
      width: 100%;
      display: flex;
      margin: 20px auto 0;
    }

    &_route {
      text-align: right;
      margin-top: 10px;
      font-size: 16px;
    }
  }

  &_cancel-order {
    &-wrap {
      padding: 32px 24px;
      display: flex;
      justify-content: flex-end;
      background-color: #fff;
      border-radius: $baseBorderRadius;
      margin-top: 4px;
    }

    &_btn {
      outline: none;
      border: none;
      cursor: pointer;
      background-color: transparent;
      font-size: 18px;
      color: $error;
      border-bottom: 1px dashed $error;
    }

    &_notify {
      font-size: 16px;
      color: $error;
    }

    &_modal {
      max-width: 256px;

      button {
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        border-radius: 4px;
        padding: 0 32px;
        cursor: pointer;
        transition: 0.3s ease;
        height: 52px;
        width: 100%;
      }
    }

    &_text {
      text-align: center;
      font-size: 18px;
      line-height: 1.1;
      color: $darkSecondary;
      margin-bottom: 32px;
    }

    &_confirm {
      background-color: $main;
      color: $trueWhite;

      &:hover {
        background: $mainHover;
      }
    }

    &_reject {
      background-color: transparent;
      color: $dark;
      margin-top: 10px;

      &:hover {
        border: 1px solid rgba($main, 0.5);
      }
    }
  }

  &_info-text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: $baseBorderRadius;
    font-size: 18px;
    line-height: 1.15;
    color: $dark;
    background-color: #fff;
    min-height: 270px;
    height: 100%;
    padding: 30px;

    &_content {
      max-width: 510px;
    }
  }

  &_info-not-owner {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #fff;
    width: 100%;
    min-height: 270px;
    height: 100%;
    padding: 30px;
    border-radius: $baseBorderRadius;

    &_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &_text {
      font-size: 24px;
      color: $dark;
      text-align: center;
      font-weight: 700;
    }

    &_subtext {
      margin-top: 35px;
      font-size: 18px;
      color: $dark;
      text-align: center;
    }

    &_img {
      margin-top: 70px;
      width: 280px;
      height: 124px;
      background: url('../../../../../new-moyo/src/img/img-pc.svg') no-repeat center;
      background-size: contain;
    }
  }

  &_notification-form {
    margin-top: 4px;
    padding: 24px;
    width: 100%;
    background-color: $light;
    border-radius: $baseBorderRadius;

    &_title {
      font-size: 24px;
      font-weight: 700;
      color: $dark;
    }

    &_text {
      margin-top: 16px;
      color: $darkSecondary;
      font-size: 18px;
      line-height: 1.15;

      &:first-child {
        margin-top: 0;
      }
    }

    &_field {
      margin-top: 16px;

      input {
        width: 100%;
        padding: 20px;
      }
    }

    &_btn {
      width: 100%;
      margin-top: 16px;
    }
  }

  &_item {
    padding: 19px 24px 16px 16px;
    background-color: #fff;
    margin-bottom: 4px;
    border-radius: $baseBorderRadius;


    &_row {
      display: flex;
    }
    &_img {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      flex-shrink: 0;
    }

    &_info {
      width: calc(100% - 80px);
    }

    &_name {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.15;
      color: $darkSecondary;
      margin-bottom: 29px;

      a {
        color: $darkSecondary;

        &:hover {
          color: $main;
        }
      }
    }

    &_details {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &_section {
        display: flex;
        align-items: flex-end;
      }

      &_price {
        display: flex;
        align-items: flex-end;

        &_old {
          color: $secondaryText;
          text-decoration: line-through;
          font-size: 12px;
          margin-right: 8px;
        }

        &_current {
          font-weight: 700;
          font-size: 18px;
          color: $darkSecondary;

          &.new-price {
            color: $error;
          }
        }
      }

      &_count {
        font-size: 16px;
        color: $darkSecondary;
        margin-right: 80px;
      }

      &_total {
        font-size: 20px;
        color: $darkSecondary;
        font-weight: 700;
      }
    }

    &_additional {
      margin-top: 27px;
      padding-left: 96px;

      &_title {
        font-size: 16px;
        font-weight: 700;
        color: $darkSecondary;
        margin-bottom: 10px;
      }

      &_item {
        font-size: 16px;
        color: $darkSecondary;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        &_price {
          font-weight: 700;
        }
      }
    }
  }

  &_details {
    padding: 32px 24px;
    background-color: #fff;
    border-radius: $baseBorderRadius;

    &_sum {
      font-size: 18px;
      color: $dark;
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
    }

    &_delivery-sum {
      font-size: 18px;
      color: $dark;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      &_value {
        &.free {
          color: #00AC45;
        }
      }
    }

    &_total-sum {
      color: $dark;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_title {
        font-size: 18px;
        font-weight: 700;
      }

      &_value {
        font-weight: 900;
        font-size: 24px;
      }
    }

    &_cashback {
      color: $dark;
      margin-top: 16px;
      background: #FFF4CB;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      align-items: center;

      &-wrap {
        display: flex;
        justify-content: flex-end;
      }

      &_text {
        font-size: 14px;
      }

      &_value {
        font-size: 16px;
        font-weight: 700;
        margin-left: 16px;
      }
    }
  }

  &_repair {

    &_information {
      text-align: right;
      margin-top: 16px;

      .link {
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -30px;
          width: 21px;
          height: 21px;
          background: url('../../../../../new-moyo/src/img/icon-info--blue.svg') no-repeat center;
          background-size: contain;
        }
      }
    }

    &-container {
      background: #FFFFFF;
      box-shadow: 0 0 2px rgba(34, 45, 74, 0.04);
      border-radius: 4px;
      padding: 24px 90px 36px;
    }

    &-main-title {
      font-weight: bold;
      font-size: 36px;
      line-height: 1.1;
      color: $dark;
      margin-bottom: 24px;
    }

    &-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 1.1;
      color: $dark;
    }

    &-subtitle {
      font-size: 18px;
      line-height: 1.1;
      color: $secondaryText;
    }

    &-text {
      font-size: 16px;
      line-height: 1.15;
      color: $darkSecondary;
    }

    &-info {
      padding-bottom: 36px;
      border-bottom: 1px dashed $border;

      &-block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-details {
      padding-top: 32px;

      &-title {
        margin-bottom: 32px;
      }
    }

    &-status {
      padding: 4px 8px 3px;
      border-radius: 2px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      background-color: $disable;

      &.in_progress {
        background-color: $hit;
      }

      &.completed {
        background-color: $third;
      }

      &.cancelled {
        background-color: $error;
      }
    }
  }
}

.question-form {
  margin-top: 4px;
}


@media screen and (max-width: 1100px) {

  .main-container {
    padding-bottom: 0;
  }

  .order-status {
    &_content {
      margin-top: 4px;
    }

    &_check {
      padding: 16px 16px 0;

      &_title {
        font-size: 24px;
        margin-bottom: 34px;
      }

      &_field-title {
        font-size: 18px;
        line-height: 1;
        font-weight: bold;
        margin-bottom: 12px;
        color: $dark;
        font-family: $baseFont;
      }

      &_form {

        &_field {
          margin-bottom: 24px;
        }
      }

      &_content {
        margin-bottom: 40px;
      }
    }

    &_shoud-be {
      font-size: 14px;
      line-height: 1.15;
      margin-bottom: 22px;
      color: $darkSecondary;
      font-family: $baseFont;
    }

    &_title {
      padding: 0 16px;
      font-size: 24px;
      margin-bottom: 24px;
    }


    &_main {
      display: block;

    }

    &_info {

      &_title {
        font-size: 18px;
      }

      &_details {
        margin-top: 18px;

        &_item {
          display: block;
          font-size: 16px;

          &_key {
            width: auto;
            margin-bottom: 4px;
          }

          &_value {
            text-align: left;
            width: auto;
          }
        }
      }
    }

    &_cancel-order {
      &-wrap {
        display: block;
      }

      &_btn {
        font-size: 16px;
      }
    }

    &_info-text {
      min-height: 230px;
      padding: 48px 22px 191px;
      font-size: 20px;
      line-height: 1.15;
      font-weight: bold;
      background: #ffffff url('../../../../../new-moyo/src/img/img-pc.svg') no-repeat center bottom 46px;
      background-size: 280px auto;
    }

    &_info-not-owner {
      padding: 30px 16px 46px;

      &_text {
        font-size: 20px;
      }

      &_subtext {
        padding: 0 18px;
      }

      &_img {
        margin-top: 30px;
        height: 94px;
        width: 194px;
      }
    }

    &_notification-form {
      padding: 20px 18px 38px;

      &_text {
        margin-top: 30px;
      }

      &_btn {
        font-size: 18px;
      }
    }

    &_item {
      padding: 16px;

      &_img {
        width: 60px;
        height: 60px;
        margin-right: 8px;
      }

      &_name {
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 10px;
        height: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &_details {
        flex-wrap: wrap;

        &_section {
          justify-content: space-between;
          width: 100%;
        }

        &_price {
          width: 100%;
        }

        &_count {
          margin-right: 0;
        }
      }

      &_additional {
        padding-left: 0;

        &_item {
          &_name {
            font-size: 14px;
            max-width: 74%;
          }
        }
      }
    }

    &_details {
      padding: 24px 16px;
    }

    &_repair {

      &-container {
        background: transparent;
        padding: 0 0 56px;
        border-radius: 0;
        box-shadow: none;
      }

      &-wrap {
        padding: 0 16px 56px;
        background-color: #fff;
      }

      &-main-title {
        padding: 0 16px;
        font-size: 24px;
        margin-bottom: 24px;
      }

      &-title {
        font-weight: bold;
        font-size: 18px;
        line-height: 1.1;
        color: $dark;
        max-width: 70%;
      }

      &-subtitle {
        font-size: 16px;
        line-height: 1.15;
        padding: 0;
      }

      &-info {
        border-radius: 0;
        background-color: #fff;
        padding: 23px 10px 39px;
        border: none;
        margin: 0 11px;

        &-block {
          flex-flow: column;
          margin-bottom: 0;
          align-items: start;

          &.status {
            flex-flow: row;
            flex-basis: 50%;
            justify-content: space-between;
            margin-bottom: 40px;

            .order-status_repair-title {
              max-width: 50%;
            }
          }
        }
      }

      &-details {
        border-radius: 0;
        background-color: #fff;
        padding: 36px 10px 39px;
        border: none;
        margin: 0 11px;

        &-title {
          margin-bottom: 36px;
        }
      }

      &_information {
        text-align: center;
        display: flex;
        justify-content: center;

        .link {
          display: inline-flex;
          align-items: center;

          &:before {
            position: static;
            transform: none;
            margin-right: 6px;
          }
        }
      }
    }
  }
}
