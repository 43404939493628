* {
	margin: 0;
	padding: 0;
}

ul, ol {
	list-style: none;
}

input, button {
	outline: none;
}
