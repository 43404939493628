@import '../../../../../new-moyo/src/styles/main/vars';

:export {
  // -common
  main: $main;
  mainHover: $mainHover;
  secondary: $secondary;
  secondaryHover: $secondaryHover;
  third: $third;
  thirdHover: $thirdHover;
  success: $success;
  error: $error;
  disable: $disable;
  base: $base;
  light: $light;
  purple: $purple;
  purpleHover: $purpleHover;
  violet: $violet;
  gray: $gray;
  vbColor: $vbColor;

  // -text
  dark: $dark;
  secondaryText: $secondaryText;
  darkSecondary: $darkSecondary;
  linkText: $linkText;

  // border
  border: $border;
  borderSecondary: $borderSecondary;

  // -labels
  new: $new;
  action: $action;
  best: $best;
  hit: $hit;
  sold: $sold;
  exclusive: $exclusive;
  advice: $advice;
  reviews: $reviews;
  present: $present;
  cashback: $cashback;
  discount: $discount;
  drawing: $drawing;
  installment: $installment;

  // fonts
  baseFont: $baseFont;
  secondFont: $secondFont;
  thirdFont: $thirdFont;
  textBaseSize: $textBaseSize;
  baseBorderRadius: $baseBorderRadius;
  commonBoxShadow: $commonBoxShadow;
  modalBackdropZindex: $modalBackdropZindex;
}
