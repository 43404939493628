@font-face {
	font-family: 'Helvetica';
	src: url('../fonts/HelveticaBold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica';
	src: url('../fonts/HelveticaRegular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Circe Extra';
	src: url('../fonts/Circe-ExtraBold.eot');
	src: local('fonts/Circe Extra Bold'), local('Circe-ExtraBold'),
	url('../fonts/Circe-ExtraBold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Circe-ExtraBold.woff2') format('woff2'),
	url('../fonts/Circe-ExtraBold.woff') format('woff'),
	url('../fonts/Circe-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Circe';
	src: url('../fonts/Circe-Regular.eot');
	src: local('Circe'), local('Circe-Regular'),
	url('../fonts/Circe-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Circe-Regular.woff2') format('woff2'),
	url('../fonts/Circe-Regular.woff') format('woff'),
	url('../fonts/Circe-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Circe';
	src: url('../fonts/Circe-Bold.eot');
	src: local('Circe Bold'), local('Circe-Bold'),
	url('../fonts/Circe-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Circe-Bold.woff2') format('woff2'),
	url('../fonts/Circe-Bold.woff') format('woff'),
	url('../fonts/Circe-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
    font-family: 'Wix Madefor Display';
    src: url('../fonts/WixMadeforDisplay-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wix Madefor Display';
    src: url('../fonts/WixMadeforDisplay-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wix Madefor Display';
    src: url('../fonts/WixMadeforDisplay-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wix Madefor Display';
    src: url('../fonts/WixMadeforDisplay-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wix Madefor Display';
    src: url('../fonts/WixMadeforDisplay-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
