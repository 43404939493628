@import "../../../../../new-moyo/src/styles/main/vars";

.question-form {
    width: 100%;
    background-color: $light;
    padding: 0;
    border-radius: $baseBorderRadius;

    &_container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
    }

    &_img {
        width: 267px;
        height: 226px;
        background: url('../../../../../new-moyo/src/img/asq-question-img-new.svg') no-repeat center;
        background-size: contain;
    }

    &_content {
        width: 405px;
        max-width: 100%;
    }

    &_title {
        font-size: 32px;
        font-weight: 700;
        color: $dark;
    }

    &_text {
        font-size: 20px;
        color: $dark;
        margin-top: 16px;
        font-weight: 700;
    }

    &_social-block {
        display: flex;
        margin-top: 24px;
        gap: 12px;

        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: auto;
            height: 40px;
            font-size: 14px;
            color: #fff;
            width: 100%;

            &.btn--tg span {
                background-image: url('../../../../../new-moyo/src/img/icon-tg--only-plane.svg');
            }

            &.btn--vb {
                background-color: $vbColor;

                span {
                    background-image: url('../../../../../new-moyo/src/img/icon-vb.svg');
                }
            }

            span{
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 25px;
                height: 25px;
                margin-left: 18px;
            }
        }
    }

    &_success-text {
        font-size: 21px;
        color: $third;
        line-height: 1.15;
    }

    &_error-text {
        font-size: 21px;
        color: $error;
        line-height: 1.15;
        margin-top: 15px;
    }
}

@media screen and (max-width: 767px) {
    .question-form {
        padding: 36px 24px;
        position: relative;

        &_container {
            flex-direction: column;
            align-items: start;
            padding: 0;
            width: auto;
        }

        &_img {
            width: 268px;
            height: 273px;
            position: absolute;
            top: 0;
            right: 0;
            background: url('../../../../../new-moyo/src/img/asq-question-img-new-mob.svg') no-repeat top right;
        }

        &_title {
            font-size: 24px;
            max-width: calc(100% - 140px);
        }

        &_text {
            margin-top: 24px;
            max-width: calc(100% - 140px);
        }

        &_social-block {
            margin-top: 36px;
            gap: 24px;
            flex-flow: column;
            align-items: center;

            .btn {
                max-width: calc(100% - 82px);
                height: 56px;
                font-size: 18px;
            }
        }
    }
}
