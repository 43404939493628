html body.react-app {
  font-family: Wix Madefor Display, sans-serif;
  color: $blackDefault;
	background-color: #F5F5F5;


  a {
    text-decoration: none;
  }

  .text {
    font-size: 14px;
    color: $blackDefault;
    line-height: 19px;

    &.small {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .b {
    font-weight: bold;
  }

  .color-lightblack {
    color: $blackDefault;
  }

  .color-blue {
    color: $blue;
  }

  use.red {
    fill: #ff0000;
  }
}

.html--modal-open,
.body--modal-open {
	overflow: hidden;
}

.credit-offer .credit-offer__propositions .select__option-item {
	min-height: 40px;
}

@media (max-width: 767px) {
	.custom-select-options-visible {
		overflow: hidden;
	}
}
